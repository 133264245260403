<template>
  <div class="body">
    <div class="header"  id="dhl">
      <el-row>
        <el-col :span="2">
          <router-link to="/">
            <img class="logo" src="@/assets/logo/logo2.png" alt="" />
          </router-link>
        </el-col>
        <el-col :span="2">
          <el-dropdown type="primary" placement="bottom-start">
            <span>
              <router-link to="/zhiyun"  class="zhi iconed">智慧云端<hr class="hr"></router-link>
            </span>
            <el-dropdown-menu slot="dropdown">
              <router-link to="/yunzichan" class="zhi2">
                <el-dropdown-item>
                  <div class="liebiao" @mouseenter="yun1" @mouseleave="yunli">
                    <p>资产平台<i class="jian_tou">></i></p> <hr class="hr2">
                  </div> 
                </el-dropdown-item>
              </router-link>
              <router-link to="/dashuju" class="zhi2">
                <el-dropdown-item>
                  <div class="liebiao" @mouseenter="yun2" @mouseleave="yunli">
                    <p>大数据集<i class="jian_tou">></i></p> <hr class="hr2">
                  </div> 
                </el-dropdown-item>
              </router-link>
              <router-link to="/shapan" class="zhi2">
                <el-dropdown-item>
                  <div class="liebiao" @mouseenter="yun3" @mouseleave="yunli">
                    <p>智能沙盘<i class="jian_tou">></i></p> <hr class="hr2">
                  </div> 
                </el-dropdown-item>
              </router-link>
              <div class="img"><img id="imgyun_1" style="position: relative; bottom: 80px;" src="@/assets/images/zhiyun.jpg" alt=""></div>
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
        <el-col :span="2">
          <el-dropdown type="primary" placement="bottom-start">
            <span>
              <router-link class="zhi iconed" to="/zhijia">智慧出行<hr class="hr"></router-link>
            </span>
            <el-dropdown-menu slot="dropdown">
              <router-link to="/zhixi" class="zhi2">
                <el-dropdown-item >
                  <div class="liebiao" @mouseenter="xing1" @mouseleave="xingli">
                    <p>智慧洗车<i class="jian_tou">></i></p> <hr class="hr2">
                  </div> 
                </el-dropdown-item>
              </router-link>
              <router-link to="/zhiting" class="zhi2">
                <el-dropdown-item >
                  <div class="liebiao" @mouseenter="xing2" @mouseleave="xingli">
                    <p>智慧停车<i class="jian_tou">></i></p> <hr class="hr2">
                  </div> 
                </el-dropdown-item>
              </router-link>
              <router-link to="/yizhangtu" class="zhi2">
                <el-dropdown-item>
                  <div class="liebiao" @mouseenter="xing3" @mouseleave="xingli">
                    <p>智慧交通<i class="jian_tou">></i></p> <hr class="hr2">
                  </div> 
                </el-dropdown-item>
              </router-link>
              <div class="img"><img id="imgxing_1" style="position: relative; bottom: 80px;" src="@/assets/images/zhixing.png" alt=""></div>
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
        <el-col :span="2">
          <el-dropdown type="primary" placement="bottom-start">
            <span>
              <router-link class="zhi iconed" to="/zhiyuan">智慧园区<hr class="hr"></router-link>
            </span>
            <el-dropdown-menu slot="dropdown">
              <router-link to="/zhixiao" class="zhi2">
                <el-dropdown-item>
                  <div class="liebiao" @mouseenter="yuan1" @mouseleave="yuanli">
                    <p>数智校园<i class="jian_tou" style="margin-left: 21px;">></i></p> <hr class="hr2">
                  </div> 
                </el-dropdown-item>
              </router-link>
              <router-link to="/wuliu" class="zhi2">
                <el-dropdown-item>
                  <div class="liebiao" @mouseenter="yuan2" @mouseleave="yuanli">
                    <p>智慧物流园<i class="jian_tou">></i></p> <hr class="hr2">
                  </div> 
                </el-dropdown-item>
              </router-link>
              <router-link to="/gongye" class="zhi2">
                <el-dropdown-item>
                  <div class="liebiao" @mouseenter="yuan3" @mouseleave="yuanli">
                     <p>智慧工业园<i class="jian_tou">></i></p> <hr class="hr2">
                  </div>
                </el-dropdown-item>
              </router-link>
              <div class="img"><img id="imgyuan_1" style="position: relative; bottom: 80px;" src="@/assets/images/zy.png" alt=""></div>
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
        <el-col :span="2">
          <el-dropdown type="primary" placement="bottom-start">
            <span>
              <router-link class="zhi iconed" to="/zhicheng"> 
                智慧城市 
                <hr class="hr">
              </router-link>
            </span>
            <el-dropdown-menu slot="dropdown">
              <router-link to="/danao" class="zhi2">
                <el-dropdown-item> 
                  <div class="liebiao" @mouseenter="cheng1" @mouseleave="chengli">
                    <p>城市信息<i class="jian_tou">></i></p ><hr class="hr2">
                  </div>
                </el-dropdown-item>
              </router-link>
              <router-link to="/moxing" class="zhi2">
                <el-dropdown-item > 
                  <div class="liebiao" @mouseenter="cheng2" @mouseleave="chengli">
                     <p>城市模型<i class="jian_tou">></i></p> <hr class="hr2"> 
                  </div>
                </el-dropdown-item>
              </router-link>
              <div class="img"><img id="imgcheng_1" src="@/assets/images/zhicheng.jpg" alt=""></div>
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
        <div 
          style="
            float: right;
            width: 50%;
          "
        >
          <ul class="daohang1">
            <li>
              <router-link class="zhi iconed" to="/huodong">活动资讯<hr class="hr"></router-link>
            </li>
            <li>
              <router-link class="zhi iconed" to="/qiye">企业介绍<hr class="hr"></router-link>
            </li>
            <li>
              <router-link class="zhi iconed" to="/lianxi">联系我们<hr class="hr"></router-link>
            </li>
            <li>
              <router-link class="zhi iconed" to="/guanyu">加入我们<hr class="hr"></router-link>
            </li>
          </ul>
        </div>
      </el-row>
    </div>


    <div class="headed">
      <el-row>
        <el-col :span="2" style="min-width: 110px;">
          <router-link to="/">
            <img class="logo" src="@/assets/logo/logo2.png" alt="" />
          </router-link>
        </el-col>
        <el-col :span="2">
          <el-dropdown type="primary" placement="bottom-start">
            <span>
              <router-link to="/zhiyun"  class="zhi iconed">智慧云端<hr class="hr"></router-link>
            </span>
            <el-dropdown-menu slot="dropdown">
              <router-link to="/yunzichan" class="zhi2">
                <el-dropdown-item>
                  <div class="liebiao" @mouseenter="yun1" @mouseleave="yunli">
                    <p>资产平台<i class="jian_tou">></i></p> <hr class="hr2">
                  </div> 
                </el-dropdown-item>
              </router-link>
              <router-link to="/dashuju" class="zhi2">
                <el-dropdown-item>
                  <div class="liebiao" @mouseenter="yun2" @mouseleave="yunli">
                    <p>大数据集<i class="jian_tou">></i></p> <hr class="hr2">
                  </div> 
                </el-dropdown-item>
              </router-link>
              <router-link to="/shapan" class="zhi2">
                <el-dropdown-item>
                  <div class="liebiao" @mouseenter="yun3" @mouseleave="yunli">
                    <p>智能沙盘<i class="jian_tou">></i></p> <hr class="hr2">
                  </div> 
                </el-dropdown-item>
              </router-link>
              <div class="img"><img id="imgyun" style="position: relative; bottom: 80px;" src="@/assets/images/zhiyun.jpg" alt=""></div>
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
        <el-col :span="2">
          <el-dropdown type="primary" placement="bottom-start">
            <span>
              <router-link class="zhi iconed" to="/zhijia">智慧出行<hr class="hr"></router-link>
            </span>
            <el-dropdown-menu slot="dropdown">
              <router-link to="/zhixi" class="zhi2">
                <el-dropdown-item >
                  <div class="liebiao" @mouseenter="xing1" @mouseleave="xingli">
                    <p>智慧洗车<i class="jian_tou">></i></p> <hr class="hr2">
                  </div> 
                </el-dropdown-item>
              </router-link>
              <router-link to="/zhiting" class="zhi2">
                <el-dropdown-item >
                  <div class="liebiao" @mouseenter="xing2" @mouseleave="xingli">
                    <p>智慧停车<i class="jian_tou">></i></p> <hr class="hr2">
                  </div> 
                </el-dropdown-item>
              </router-link>
              <router-link to="/yizhangtu" class="zhi2">
                <el-dropdown-item>
                  <div class="liebiao" @mouseenter="xing3" @mouseleave="xingli">
                    <p>智慧交通<i class="jian_tou">></i></p> <hr class="hr2">
                  </div> 
                </el-dropdown-item>
              </router-link>
              <div class="img"><img id="imgxing" style="position: relative; bottom: 80px;" src="@/assets/images/zhixing.png" alt=""></div>
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
        <el-col :span="2">
          <el-dropdown type="primary" placement="bottom-start">
            <span>
              <router-link class="zhi iconed" to="/zhiyuan">智慧园区<hr class="hr"></router-link>
            </span>
            <el-dropdown-menu slot="dropdown">
              <router-link to="/zhixiao" class="zhi2">
                <el-dropdown-item>
                  <div class="liebiao" @mouseenter="yuan1" @mouseleave="yuanli">
                    <p>数智校园<i class="jian_tou" style="margin-left: 21px;">></i></p> <hr class="hr2">
                  </div> 
                </el-dropdown-item>
              </router-link>
              <router-link to="/wuliu" class="zhi2">
                <el-dropdown-item>
                  <div class="liebiao" @mouseenter="yuan2" @mouseleave="yuanli">
                    <p>智慧物流园<i class="jian_tou">></i></p> <hr class="hr2">
                  </div> 
                </el-dropdown-item>
              </router-link>
              <router-link to="/gongye" class="zhi2">
                <el-dropdown-item>
                  <div class="liebiao" @mouseenter="yuan3" @mouseleave="yuanli">
                     <p>智慧工业园<i class="jian_tou">></i></p> <hr class="hr2">
                  </div>
                </el-dropdown-item>
              </router-link>
              <div class="img"><img id="imgyuan" style="position: relative; bottom: 80px;" src="@/assets/images/zy.png" alt=""></div>
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
        <el-col :span="2">
          <el-dropdown type="primary" placement="bottom-start">
            <span>
              <router-link class="zhi iconed" to="/zhicheng"> 
                智慧城市 
                <hr class="hr">
              </router-link>
            </span>
            <el-dropdown-menu slot="dropdown">
              <router-link to="/danao" class="zhi2">
                <el-dropdown-item> 
                  <div class="liebiao" @mouseenter="cheng1" @mouseleave="chengli">
                    <p>城市信息<i class="jian_tou">></i></p ><hr class="hr2">
                  </div>
                </el-dropdown-item>
              </router-link>
              <router-link to="/moxing" class="zhi2">
                <el-dropdown-item > 
                  <div class="liebiao" @mouseenter="cheng2" @mouseleave="chengli">
                     <p>城市模型<i class="jian_tou">></i></p> <hr class="hr2"> 
                  </div>
                </el-dropdown-item>
              </router-link>
              <div class="img"><img id="imgcheng" src="@/assets/images/zhicheng.jpg" alt=""></div>
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
        <div 
          style="
            float: right;
            width: 50%;
          "
        >
          <ul class="daohang1">
            <li>
              <router-link class="zhi iconed" to="/huodong">活动资讯<hr class="hr"></router-link>
            </li>
            <li>
              <router-link class="zhi iconed" to="/qiye">企业介绍<hr class="hr"></router-link>
            </li>
            <li>
              <router-link class="zhi iconed" to="/lianxi">联系我们<hr class="hr"></router-link>
            </li>
            <li>
              <router-link class="zhi iconed" to="/guanyu">加入我们<hr class="hr"></router-link>
            </li>
          </ul>
        </div>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  mounted() {
    window.addEventListener('scroll', function () {

    })
    
    window.addEventListener('wheel', function(event) {
      const header = document.querySelector('.header');
      const headed = document.querySelector('.headed');
      if (window.scrollY  != 0) {
        if (event.deltaY > 0) {
          // 鼠标滚轮向下滚动
          header.style.top = "-90px";
          headed.style.top = "0px";
          // console.log("向下滚动");
        } else {
          // 鼠标滚轮向上滚动
          header.style.top = "0px";
          headed.style.top = "-90px";
          // console.log("向上滚动");
        }
      }
    });
  },
  methods:{

    // 智慧云二级导航图片切换
    yun1: function(){
      const imag = document.getElementById('imgyun');
      imag.setAttribute('src',require('@/assets/images/zcgl.png'));
      const imag1 = document.getElementById('imgyun_1');
      imag1.setAttribute('src',require('@/assets/images/zcgl.png'));
    },
    yun2: function(){
      const imag = document.getElementById('imgyun');
      imag.setAttribute('src',require('@/assets/images/dsjj.png'));
      const imag1 = document.getElementById('imgyun_1');
      imag1.setAttribute('src',require('@/assets/images/dsjj.png'));
    },
    yun3: function(){
      const imag = document.getElementById('imgyun');
      imag.setAttribute('src',require('@/assets/zhiyun/chengshimoxing.png'));
      const imag1 = document.getElementById('imgyun_1');
      imag1.setAttribute('src',require('@/assets/zhiyun/chengshimoxing.png'));
    },
    yunli: function(){
      // const imag = document.getElementById('imgyun');
      // imag.setAttribute('src',require('@/assets/images/zhiyun.jpg'));
      // const imag1 = document.getElementById('imgyun_1');
      // imag1.setAttribute('src',require('@/assets/images/zhiyun.jpg'));
    },


    // 智慧行二级导航图片切换
    xing1: function(){
      const imag = document.getElementById('imgxing');
      imag.setAttribute('src',require('@/assets/images/znxc.png'));
      const imag1 = document.getElementById('imgxing_1');
      imag1.setAttribute('src',require('@/assets/images/znxc.png'));
    },
    xing2: function(){
      const imag = document.getElementById('imgxing');
      imag.setAttribute('src',require('@/assets/images/zntc.png'));
      const imag1 = document.getElementById('imgxing_1');
      imag1.setAttribute('src',require('@/assets/images/zntc.png'));
    },
    xing3: function(){
      const imag = document.getElementById('imgxing');
      imag.setAttribute('src',require('@/assets/images/znjt.png'));
      const imag1 = document.getElementById('imgxing_1');
      imag1.setAttribute('src',require('@/assets/images/znjt.png'));
    },
    xingli: function(){
      // const imag = document.getElementById('imgxing');
      // imag.setAttribute('src',require('@/assets/images/zhixing.png'));
      // const imag1 = document.getElementById('imgxing_1');
      // imag1.setAttribute('src',require('@/assets/images/zhixing.png'));
    },


    // 智慧园二级导航图片切换
    yuan1: function(){
      const imag = document.getElementById('imgyuan');
      imag.setAttribute('src',require('@/assets/images/zhxy.png'));
      const imag1 = document.getElementById('imgyuan_1');
      imag1.setAttribute('src',require('@/assets/images/zhxy.png'));
    },
    yuan2: function(){
      const imag = document.getElementById('imgyuan');
      imag.setAttribute('src',require('@/assets/images/rd.png'));
      const imag1 = document.getElementById('imgyuan_1');
      imag1.setAttribute('src',require('@/assets/images/rd.png'));
    },
    yuan3: function(){
      const imag = document.getElementById('imgyuan');
      imag.setAttribute('src',require('@/assets/images/zhyq.png'));
      const imag1 = document.getElementById('imgyuan_1');
      imag1.setAttribute('src',require('@/assets/images/zhyq.png'));
    },
    yuanli: function(){
      // const imag = document.getElementById('imgyuan');
      // imag.setAttribute('src',require('@/assets/images/zy.png'));
      // const imag1 = document.getElementById('imgyuan_1');
      // imag1.setAttribute('src',require('@/assets/images/zy.png'));
    },


    // 智慧城二级导航图片切换
    cheng1: function(){
      const imag = document.getElementById('imgcheng')
      imag.setAttribute('src',require('@/assets/images/traffic.jpg'));
      const imag1 = document.getElementById('imgcheng_1');
      imag1.setAttribute('src',require('@/assets/images/traffic.jpg'));
    },
    cheng2: function(){
      const imag = document.getElementById('imgcheng')
      imag.setAttribute('src',require('@/assets/images/ditu.jpg'));
      const imag1 = document.getElementById('imgcheng_1');
      imag1.setAttribute('src',require('@/assets/images/ditu.jpg'));
    },
    chengli: function(){
      // const imag = document.getElementById('imgcheng')
      // imag.setAttribute('src',require('@/assets/images/zhicheng.jpg'));
      // const imag1 = document.getElementById('imgcheng_1');
      // imag1.setAttribute('src',require('@/assets/images/zhicheng.jpg'));
    }
  }
};
</script>

<style scoped>
.logo {
  width: 110px;
  margin-top: 4px;
}
a {
  text-decoration: none;
}
.el-col {
  height: 60px;
  text-align: center;
  transition: all 3s;
}
.zhi {
  line-height: 60px;
  font-size: 15px;
}
.hr{
  display: none;
  position: relative;
  top: -3px;
  height: 2px;
  border: 0px;
  background-color: rgb(90, 156, 248);
}
.zhi:hover{
  .hr{
    display: block;
  }
}
.textName {
  color: #000;
  line-height: 60px;
  font-weight: 700;
}
.iconed{
    display: block;
    width: 110px;
    height: 66px;
}
.header{
  width: 100%;
  z-index: 9999;
  transition: all 2s;
  transition: top .3s linear;
  position: fixed;
  top: -90px ;
  background-color: #ffffff;
  min-width: 1000px;
}
.headed{
  background-color: #ffffff;
  position: absolute;
  width: 100%;
  z-index: 99;
  top: 0px;
  min-width: 1000px;
}
.daohang1{
  width: 85%;
  height: 60px;
  display: flex;
  font-size: 15px;
  float: right;
}
.daohang1 li{
  list-style: none;
  width: 90%;
}
.hr2{
  height: 1px;
  border: 0px;
  background-color: rgb(194, 194, 194);
  width: 180px;
  margin: 0 auto;
}
.el-dropdown-menu {
  width: 600px;
  height: 250px;
}
.el-dropdown-menu li {
  width: 30%;
  height: 50px;
  margin-top: 20px;
  font-size: 15px;
  padding-top: 10px;
}
.jian_tou{
  margin-left: 15px;
  position:absolute;
  transition: transform 0.3s ease;
}
.el-dropdown-menu li:hover{
  .jian_tou{
    position:absolute;
    left: 150px;
    transform: translateX(15px);
  }
}
.el-dropdown-menu li:hover{
  .hr2{
  background-color: rgb(90, 156, 248);
  }
}
.img{
  width: 50%;
  height: 200px;
  float: right;
  margin-right: 35px;
  position: relative;
  bottom: 135px;
}
.img img{
  width: 100%;
  height: 200px;
  box-shadow: 0px 7px 14px 2px rgba(0, 15, 79, 0.05);
  border-radius: 10px;
  border: 1px solid rgb(211, 210, 210);
  transition: transform 0.5s ease-out;
}
.img img:hover{
  transform: scale(1.02);
}
.liebiao{
  position: relative;
  bottom: 10px;
  right: 20px;
  width: 220px;
  height: 49px;
  padding: 0px;
  padding-top: 10px; ;
}
</style>
