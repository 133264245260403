var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"shenTi",attrs:{"id":"shenTi"}},[_vm._m(0),_c('div',{staticClass:"neirong",on:{"mouseenter":_vm.xianShi,"mouseleave":_vm.hide}},[_c('img',{staticClass:"img",attrs:{"src":require("@/assets/zuJian/icon2.png"),"alt":""}}),_vm._m(1)]),_c('div',{staticClass:"reXian1",on:{"mouseenter":_vm.xianShi,"mouseleave":_vm.hide}}),_c('div',{staticClass:"reXian",attrs:{"id":"reXian"},on:{"mouseenter":_vm.xianShi,"mouseleave":_vm.hide}},[_vm._m(2),_c('p',{staticClass:"haoMa"},[_vm._v("187-6560-9456")])]),_vm._m(3),_vm._m(4),_c('div',{staticClass:"neirong1",on:{"click":_vm.dainji_shouQi}},[_vm._m(5),_c('img',{staticClass:"img",attrs:{"src":require("@/assets/zuJian/right.png"),"alt":""}})])]),_c('div',{staticClass:"shiFang",attrs:{"id":"shiFang"},on:{"click":_vm.dainji_shiFang}},[_c('img',{staticClass:"img",attrs:{"src":require("@/assets/zuJian/right.png"),"alt":""}}),_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"neirong",staticStyle:{"border-radius":"50px"}},[_c('img',{staticStyle:{"width":"65px","height":"65px"},attrs:{"src":require("@/assets/zuJian/renGongTouXiang.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('b',[_c('p',[_vm._v("服务热线")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('b',[_c('p',{staticClass:"dianHua"},[_vm._v("联系电话")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"/yuYueYanShi"}},[_c('div',{staticClass:"neirong"},[_c('img',{staticClass:"img",attrs:{"src":require("@/assets/zuJian/icon3.png"),"alt":""}}),_c('p',[_vm._v("预约演示")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"/wangZhanDiTu"}},[_c('div',{staticClass:"neirong"},[_c('img',{staticClass:"img",staticStyle:{"width":"48px","height":"48px"},attrs:{"src":require("@/assets/zuJian/icon4.png"),"alt":""}}),_c('p',[_vm._v("网站地图")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('b',[_c('p',{staticStyle:{"position":"relative","right":"10px","top":"1px"}},[_vm._v("收起")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('b',[_c('p',[_vm._v("展 开")])])
}]

export { render, staticRenderFns }