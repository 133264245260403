<template>
    <div>
        <div class="shenTi" id="shenTi">
            <div class="neirong" style="border-radius: 50px;">
                <img style="width: 65px; height: 65px;" src="@/assets/zuJian/renGongTouXiang.png" alt="">
            </div>
            <div class="neirong" @mouseenter="xianShi" @mouseleave="hide" >
                <img class="img" src="@/assets/zuJian/icon2.png" alt="">
                <b><p>服务热线</p></b>
            </div>
            <div class="reXian1" @mouseenter="xianShi" @mouseleave="hide" ></div>
            <div class="reXian" id="reXian" @mouseenter="xianShi" @mouseleave="hide">
                <b><p class="dianHua">联系电话</p></b>
                <p class="haoMa">187-6560-9456</p>
            </div>
            <a href="/yuYueYanShi">
                <div  class="neirong">
                    <img class="img" src="@/assets/zuJian/icon3.png" alt="">
                    <p>预约演示</p>
                </div>
            </a>
            <a href="/wangZhanDiTu">
                 <div class="neirong">
                    <img class="img" style="width: 48px; height: 48px;" src="@/assets/zuJian/icon4.png" alt="">
                    <p>网站地图</p>
                </div>
            </a>
            <div class="neirong1" @click="dainji_shouQi">
                <b><p style="position: relative; right: 10px; top: 1px;">收起</p></b>
                <img class="img" src="@/assets/zuJian/right.png" alt="">
            </div>
        </div>
        <div id="shiFang" class="shiFang" @click="dainji_shiFang">
            <img class="img" src="@/assets/zuJian/right.png" alt="">
            <b><p>展&nbsp;开</p></b>
        </div>
    </div>
</template>

<script>

import $ from 'jquery';

export default {
  data() {
    return {

    };
  },
  methods: {
    xianShi: function(){
        const reXian = document.getElementById('reXian');
        $(".reXian").css('display','block');
        $(".reXian1").css('display','block');
        reXian.style.opacity = 1; 
    },
    hide: function () {
      $(".reXian").css('display','none');
      $(".reXian1").css('display','none');
    },

    dainji_shouQi(){
        var shenTi = document.getElementById('shenTi');
        shenTi.style.right = '-100px';
        $(".shiFang").css('display','block');
        // console.log("点击收起");
    },
    dainji_shiFang(){
        var shenTi = document.getElementById('shenTi');
        shenTi.style.right = '0px';
        $(".shiFang").css('display','none');
        // console.log("点击施放");
    }
  },
};

</script>

<style scoped>
    .shenTi{
        position: fixed;
        z-index: 99999;
        right: 0;
        top: 300px;
        width: 70px;
        height: 320px;
        margin-right: 5px;
    }
    #shiFang{
        position: fixed;
        z-index: 99999;
        right: -20px;
        top: 300px;
        width: 30px;
        height: 63px;
        margin-right: 5px;
        background-color: white;
        border-radius: 14px;
        transition: transform 0.3s ease;
        box-shadow: 1px 2px 7px 0px rgba(150, 149, 149, 0.5);
        display: none;
    }
    #shiFang p{
        writing-mode: vertical-rl;
        text-orientation: mixed;
        position: relative;
        left: 5px;
        margin-bottom: 3px;
    }
    #shiFang img{
        margin-top: 3px;
        margin-bottom: 7px;
        margin-right: 5px;
        width: 25px; 
        height: 20px;
        transform: rotate(180deg);
    }
    #shiFang:hover{
        transform: translateX(-15px);
    }
    .neirong{
        width: 65px;
        height: 65px;
        margin-bottom: 7px;
        border-radius: 10px;
        background-color: rgb(255, 255, 255);
        transform: all .4s;
        box-shadow: 1px 2px 7px 0px rgba(150, 149, 149, 0.5);
    }
    .neirong1{
        width: 65px;
        height: 20px;
        margin-bottom: 7px;
        border-radius: 10px;
        background-color: rgb(255, 255, 255);
        transform: all .4s;
        box-shadow: 1px 2px 7px 0px rgba(150, 149, 149, 0.5);
    }
    .neirong:hover{
        transform: scale(1.04);
    }
    .neirong1:hover{
        transform: scale(1.04);
    }
    .neirong1 img{
        position: relative;
        bottom: 17px;
        left: 15px;
        width: 25px; 
        height: 20px;
    }
    p{
        position: relative;
        bottom: 7px;
        font-size: 12px;
    }
    a{
        text-decoration: none;
    }
    .reXian{
        position: absolute;
        right: 95px;
        bottom: 150px;
        width: 225px;
        height: 120px;
        border-radius: 10px;
        background-color: white;
        box-shadow: 1px 2px 7px 0px rgba(150, 149, 149, 0.5);
        display: none;
    }
    #reXian{
        opacity: 0;     
    }
    .reXian1{
        position: absolute;
        right: 66px;
        bottom: 182px;
        width: 30px;
        height: 64px;
        z-index: 1;
        display: none;
    }
    .reXian::before{
        content: '▶';
        position: absolute;
        font-size: 30px;
        right: -11px;
        top: 40px;
        color: white;
    }
    .dianHua{
        margin-top: 25px;
        text-align: left;
        padding-left: 20px;
        font-size: 20px;
    }
    .haoMa{
        margin-top: 20px;
        font-size: 27px;
        color: #377cff;
    }
</style>