import Vue from 'vue'
import App from './App.vue'
// import App from './App.vue'
import router from './router'
import store from './store'
import Iframe from '@/components/header/hea-der.vue'
import activity from '@/components/activity/actiVity.vue'
import scrollTop from '@/components/scrollTop/scrollTop.vue'
// import Iframe from './views/hea-der.vue' scrollTop
import Iframed from './components/foo/foo-index.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'amfe-flexible'
import dataV from '@jiaminghi/data-view'
import serviceWindow from '@/components/service_window/serviceWindow.vue'
import contactWindow from '@/components/contact_Window/contactWindow.vue'


Vue.use(dataV)
Vue.use(ElementUI)


// eslint-disable-next-line vue/multi-word-component-names
Vue.component('Iframe', Iframe)
// eslint-disable-next-line vue/multi-word-component-names
Vue.component('Iframed', Iframed)
// eslint-disable-next-line vue/multi-word-component-names
Vue.component('activity', activity)
Vue.component('scrollTop', scrollTop)
Vue.component('serviceWindow', serviceWindow )
Vue.component('contactWindow', contactWindow)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
