<template>
  <div class="footer">
    <div class="footered">
      <!-- 左侧图标 -->
      <div class="bannerz">
        <div class="logo">
          <router-link to="/">
            <img class="logo" src="@/assets/logo/logo1.png" alt="万维腾景" />
          </router-link>
        </div>
      </div>
      <!-- 右侧功能 -->
      <div class="bannery">
        <router-link to="/zhiyun">智慧云端</router-link>&nbsp;
        <router-link to="/zhijia">智慧出行</router-link>&nbsp;
        <router-link to="/zhiyuan">智慧园区</router-link>&nbsp;
        <router-link to="/zhicheng">智慧城市</router-link>&nbsp;
        <span style="color: white; margin-left: 9px; margin-right: 9px;">|</span>
        <router-link to="/huodong">活动资讯</router-link>&nbsp;
        <router-link to="/qiye">企业介绍</router-link>&nbsp;
        <router-link to="/lianxi">联系我们</router-link>&nbsp;
        <router-link to="/guanyu">加入我们</router-link>&nbsp;
      </div>
    </div>
    <!-- 左侧内容 -->
    <div class="neirong">
      <div class="neirong1">
        <div class="neiBiaoTi">
          <p>合作企业</p>
        </div>
        <div class="neiContent">
          <p>海康威视</p>
          <p>北京四维腾景</p>
          <p>爱德发科技有限公司</p>
          <p></p>
        </div>
      </div>
      <div class="neirong1" style=" text-align: center; margin-left: 30px;">
        <div class="neiBiaoTi">
          <p>了解更多</p>
        </div>
        <div class="neiContent">
          <a href="/huodong"><p>活动资讯</p></a>
          <a href="/qiye"><p>企业介绍</p></a>
          <a href="/lianxi"><p>合作共赢</p></a>
        </div>
      </div>
      <div class="neirong1">
        <div class="neiBiaoTi">
          <p>友情链接</p>
        </div>
        <div class="neiContent">
          <router-link :to="{ name: 'diTu', query: { aa: 'xiche' }}" target="_blank">
            <b><p @mouseenter="xcgzhXian" @mouseleave="xcgzhHide" style=" width: 117px;">骈邑自助洗车平台</p></b>
          </router-link>
          <div @mouseenter="xcgzhXian" @mouseleave="xcgzhHide" class="xc2"></div>
          <div @mouseenter="xcgzhXian" @mouseleave="xcgzhHide" class="xc" id="xc">
            <img src="@/assets/images/pyxc.jpg" alt="">
          </div>
          <a href="http://zc.wanweilq.com/" target="_blank"><p>云资产经营管理平台</p></a>
          <a href=""><p>停车服务管理平台</p></a>
        </div>
      </div>
    </div>
    <!-- 联系电话邮箱 -->
    <div class="lx">
      <h3 @mouseenter="xianShi" @mouseleave="hide"> <span class="lxBiaoTi">&lt;</span>快速找到我们的方式</h3>
      <div class="weiXin"  @mouseenter="xianShi" @mouseleave="hide">
        <img style=" width: 32px; height: 30px; position: relative; right: 10px; top: 5px;" src="@/assets/images/weixin.png" alt="">
        <span style="color: black; position: relative; bottom: 4px; font-size: 16px; font-family: '黑体', sans-serif;">微信公众号</span>
        <img src="@/views/component/image/erweima.png" alt="">
      </div>
      <div class="dianhua">
        <img src="@/assets/images/tel.png" alt="" />
        <span style="height: 30px; position: relative; bottom: 4px; margin-bottom: 5px; padding-bottom: 5px; margin-left: 15px;">
          187-6560-9456
        </span>
      </div>
      <div class="email">
        <img src="@/assets/images/email.png" alt="" />
        <span style="height: 30px; position: relative; bottom: 7px; left: 5px; margin-bottom: 5px; padding-bottom: 5px; margin-left: 15px;">
          960010302@qq.com
        </span>
      </div>
      <router-link :to="{ name: 'diTu', query: { aa: 'gongsi' }}" target="_blank" style="color: rgb(236, 236, 236);">
        <div class="dizhi">
          <img style=" margin-top: 5px;" src="@/assets/images/dizhi.png" alt="" />
          <span style=" position: relative; bottom: 18px; left: 4px; margin-bottom: 5px; padding-bottom: 5px; margin-left: 15px; padding-right: 15px;">
            山东省潍坊市临朐县东城街道兴隆路2134号
          </span>
        </div>
      </router-link>
    </div>
    <div class="copyright">
      <p class="fflink">
        <router-link to="/yinSi">隐私政策</router-link>&nbsp;
        <router-link to="/mianZe">免责声明</router-link>&nbsp;
        <router-link to="/zhizheng">营业执照</router-link>&nbsp;
        <!-- <router-link to="/">联系我们</router-link>&nbsp; -->
        <span  @mouseenter="enter" @mouseleave="leave" class="showTooltip">
          联系我们
          <div @mouseleave="leave" class="div">
            <img @mouseover="mouseover"  class="tooltip" src="@/views/component/image/erweima.png" alt="">
          </div>
        </span>
      </p>
      <div class="fflink3">
        <p class="fflink2" style="font-size: 13px;">©&nbsp;2023&nbsp;wanweilq.com</p>
        <p class="fflink2" style="font-size: 12px;">临朐万维腾景数字技术有限公司&nbsp;版权所有</p>
        <p class="fflink2"><a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">鲁ICP备2023018119号</a></p>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';

export default {
  data() {
    return {

    };
  },
  methods: {
    enter: function() {
      $(".div").css('display','block');
      $(".tooltip").css('display', 'block');
    },
    leave: function () {
      $(".tooltip").css('display', 'none');
    },
    mouseover: function () {
      $(".tooltip").css('display', 'block');
    },

    xianShi: function(){
      $(".weiXin").css('display','block');
    },
    hide: function () {
      $(".weiXin").css('display','none');
    },


    xcgzhXian: function(){
      const xc = document.getElementById('xc');
      $(".xc").css('display','block');
      $(".xc2").css('display','block');
      xc.style.opacity = 1;
    },
    xcgzhHide: function () {
      $(".xc").css('display','none');
      $(".xc2").css('display','none');

    }
  }
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
}
.footer {
  width: 100%;
  height: 500px;
  background-color: #212b4e;
  min-width: none;
}
.footered {
  height: 120px;
  line-height: 120px;
  margin: 0 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #999;
}
.logo {
  margin-left: 5%; 
  margin-top: 5px;
  width: 280px;
  height: 90px;
}
.bannery a {
  color: #fff;
  text-decoration: none;
  font-size: 15px;
  margin-left: 10px;
}
.neirong{
  float: left;
  position: absolute;
  width: 700px;
  height: 250px;
  margin-left: 120px;
  margin-top: 20px;
}
.neirong1{
  width: 170px;
  height: 200px;
  float: left;
  margin-left: 45px;
  text-align: left;
  margin-top: 37px;
}
.neiBiaoTi{
  height: 20px;
}
.neiBiaoTi p {
  font-size: 15.5px;
  color: rgb(236, 236, 236);
}
.neiContent{
  height: 175px;
}
.neiContent p {
  font-size: 14.5px;
  color: rgb(214, 214, 214);
  margin-left: 2px;
  margin-top: 20px;
}
.neiContent p:hover{
  color: #fff;
}
.xc{
  position: absolute;
  right: -65px;
  bottom: 70px;
  width: 150px;
  height: 150px;
  border-radius: 50px;
  background-color: #fff;
  box-shadow: 1px 1px 10px 0.5px rgba(173, 172, 172, 0.5);
  display: none;
  z-index: 999;
}
#xc{
  opacity: 0;
}
.xc2{
  position: absolute;
  right: 80px;
  bottom: 150px;
  width: 41px;
  height: 27px;
  display: none;
}
.xc::before{
  content: '◀';
  position: absolute;
  font-size: 30px;
  left: -10px;
  top: 40px;
  color: white;
}
.xc img{
  width: 140px;
  height: 140px;  
  border-radius: 50px;
  margin-left: 5px;
  margin-top: 5px;
}
.lx {
  color: rgb(236, 236, 236);
  width: 500px;
  height: 200px;
  float: right;
  margin-right: 100px;
  margin-top: 50px;
}
.lx h3{
  margin-bottom: 20px;
  /* border: 1px solid rgb(255, 0, 0); */
  width: 275px;
  position: relative;
  left: 223px;
  text-align: right;
}
.lxBiaoTi{
  position:absolute;
  left: 25px;
  top: 3px;
  transition: transform 0.3s ease;
}
.lx h3:hover{
  .lxBiaoTi{
    position:absolute;
    transform: translateX(-15px);
  }
}
.weiXin{
  position: absolute;
  float: left;
  width: 200px;
  height: 200px;
  margin-top: -53px;
  margin-left: 23px;
  box-shadow: 2px 2px 7px 1px rgba(150, 149, 149, 0.5);
  background-color: #ffffff;
  border-radius: 25px;
  border-top-right-radius: 13px;
  display: none;
  z-index: 1;
  @media (max-width: 1313px) {
    margin-top: -10px;
    margin-left: 280px;
  }
}
.weiXin img {
  margin-top: 3px;
  width: 150px;
  height: 150px;
}
.dianhua{
  font-size: 25px;
  width: 230px;
  position: relative;
  left: 268px;
}
.email{
  margin-top: 10px;
  font-size: 17.9px;
  width: 230px;
  position: relative;
  left: 268px;
}
.dizhi{
  margin-top: 10px;
  width: 230px;
  height: 48px;
  float: right;
}
.dizhi img{
  width: 33px;
  height: 33px;
}
div img {
  width: 30px;
  height: 30px;
}
.llx {
  margin-top: 35px;
}
.llx ul li {
  font-size: 20px;
}
.llx ul li a {
  color: #fff;
  text-decoration: none;
}
.llx ul li a img {
  width: 40px;
  height: 40px;
}
.copyright {
  margin-top: 303px;
  background-color: black;
  height: 75px;
}
.copyright a {
  text-decoration: none;
  color: gray;
  font-size: 10px;
  font-size: 13px;
}
.copyright a:hover{
  color: rgba(255, 255, 255, 0.993);
}
.fflink {
  display:inline;
  position: relative;
  top: 20px;
  float: left;
  margin-left: 60px;
}
.fflink2{
  color: gray;
  font-size: 10px;
  margin-right: 30px;
  display:inline;
}
.fflink3{
  display:inline;
  margin-top: 20px;
  height: 30px;
  padding-top: 10px;
  float: right;
  margin-right: 50px;
}
.fflink a {
  height: 40px;
  line-height: 40px;
  margin-left: 20px;
}
.fflink a:hover{
  color: rgba(255, 255, 255, 0.993);
}
.showTooltip{
  text-decoration: none;
  color: gray;
  font-size: 10px;
  font-size: 13px;
  font-weight: bold;
  margin-left: 20px;
  position: relative;
  z-index: 1;
}
.tooltip {
  position: absolute;
  background-color: #fff;
  padding: 10px;
  width: 120px;
  height: 120px;
  border-radius: 33px;
  border-bottom-left-radius: 8px ;
  top: -140px;
  left: 0px;
  z-index: 2;
  display: none;
  margin-bottom: 20px;
}
.div{
  display: none;
  width: 120px;
  height: 10px;
  position: absolute;
  left: 0px;
  top: -10px;
}
.ffink {
  padding: 0 50px;
}
router-link {
  text-decoration: none;
}
</style>
