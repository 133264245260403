import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 一级路由
export const constantRoutes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/index-s.vue')
  },
  {
    path: '/zhiyun',
    name: 'zhiyun',
    component: () => import('../views/zhi-yun.vue')
  },
  {
    path: '/zhijia',
    name: 'zhijia',
    component: () => import('../views/zhi-jia.vue')
  },
  {
    path: '/zhiyuan',
    name: 'zhiyuan',
    component: () => import('../views/zhi-yuan.vue')
  },
  {
    path: '/zhicheng',
    name: 'zhicheng',
    component: () => import('../views/zhi-cheng.vue')
  },
  {
    path: '/huodong',
    name: 'huodong',
    component: () => import('../views/huo-dong.vue')
  },
  {
    path: '/qiye',
    name: 'qiye',
    component: () => import('../views/qiYe.vue')
  },
  {
    path: '/guanyu',
    name: 'guanyu',
    component: () => import('../views/guan-yu.vue')
  },
  {
    path: '/lianxi',
    name: 'lianxi',
    component: () => import('../views/lian-xi.vue')
  },
  {
    path: '/yinSi',
    name: 'yinSi',
    component: () => import('../views/yinSiZhengCe.vue')
  },
  {
    path: '/mianZe',
    name: 'mianZe',
    component: () => import('../views/mianZeShengMing.vue')
  },
  {
    path: '/zhizheng',
    name: 'zhizheng',
    component: () => import('../views/zhi-zheng.vue')
  },
  {
    path: '/yuYueYanShi',
    name: 'yuYueYanShi',
    component: () => import('../views/yuYueYanShi.vue')
  },
  {
    path: '/wangZhanDiTu',
    name: 'wangZhanDiTu',
    component: () => import('../views/wangZhanDiTu.vue')
  },
  {
    path: '/diTu',
    name: 'diTu',
    component: () => import('../views/diTu.vue'),
  }
]

// 二级路由
export const asyncRoutes = [
  {
    path: '/yunzichan',
    name: 'yunzichan',
    component: () => import('../views/yunZichan.vue')
  },
  {
    path: '/zhiting',
    name: 'zhiting',
    component: () => import('../views/zhi-ting.vue')
  },
  {
    path: '/zhixi',
    name: 'zhixi',
    component: () => import('../views/zhi-xi')
  },
  {
    path: '/yizhangtu',
    name: 'yizhangtu',
    component: () => import('../views/yizhang-tu.vue')
  },
  {
    path: '/wuliu',
    name: 'wuliu',
    component: () => import('../views/wuLiu.vue')
  },
  {
    path: '/gongye',
    name: 'gongye',
    component: () => import('../views/gongYeZhiHuiYuanQu.vue')
  },
  {
    path: '/zhixiao',
    name: 'zhixiao',
    component: () => import('../views/shuZhiXiaoYuan.vue')
  },
  {
    path: '/danao',
    name: 'danao',
    component: () => import('../views/daNao.vue')
  },
  {
    path: '/dashuju',
    name: 'dashuju',
    component: () => import('../views/daShuju.vue')
  },
  {
    path: '/shapan',
    name: 'shapan',
    component: () => import('../views/shaPan.vue')
  },
  {
    path: '/moxing',
    name: 'moxing',
    component: () => import('../views/moXing.vue')
  },

]

// 活动路由
export const activityRoutes = [
  {
    path: '/huodong1',
    name: 'huodong1',
    component: () => import('@/views/component/huoDong1.vue')
  },
  {
    path: '/huodong2',
    name: 'huodong2',
    component: () => import('@/views/component/huoDong2.vue')
  },
  {
    path: '/huodong3',
    name: 'huodong3',
    component: () => import('@/views/component/huoDong3.vue')
  },
  {
    path: '/huodong4',
    name: 'huodong4',
    component: () => import('@/views/component/huoDong4.vue')
  },
  {
    path: '/huodong5',
    name: 'huodong5',
    component: () => import('@/views/component/huoDong5.vue')
  },
  {
    path: '/huodong6',
    name: 'huodong6',
    component: () => import('@/views/component/huoDong6.vue')
  },
  {
    path: '/huodong7',
    name: 'huodong7',
    component: () => import('@/views/component/huoDong7.vue')
  }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [...constantRoutes, ...asyncRoutes, ...activityRoutes],

  scrollBehavior (to, from, savedPosition) { 
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
 }
})

export default router
