<template>
  <div>
    <div class="top header"  v-show="top" @click="istop">
      <img src="@/assets/向上.png" class="img" >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      top:false, // 显示与隐藏
    };
  },
  mounted() {
    window.addEventListener("scroll", () => {
      let html = document.documentElement
      if (html.scrollTop >= 100) {
        this.top = true
      } else {
        this.top = false
      }
    })
  },
  methods: {
    istop(){
      let html = document.documentElement
      var timer = setInterval(() => {
        if (html.scrollTop<=0) {
          clearInterval(timer)
        }
        html.scrollTop = html.scrollTop - 20
      }, 10);
    }
  }
};
</script>

<style scoped>
.header{
  width: 50px;
  height: 50px;
  line-height: 6vh;
  background-color: #c0c0c0;
  border-radius: 50%;
  position: fixed;
  bottom: 10px;
  right: 70px;
  transform: translateX(100%);
  z-index: 9999;
  box-shadow: 1px 2px 7px 0px rgba(214, 214, 214, 0.5);
}
.img{
  width: 30px;
  height: 30px;
  margin-right: 2px;
  margin-top: 8.5px;
  transition: all 0.3s;
}
.header:hover{
  .img{
    transform: scale(1.1);
  }
}
</style>