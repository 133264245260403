<template>
    <div>
        <div class="kuangJia" id="kuangJia">
            <h1>点击申请，即可获得免费体验机会</h1>
            <h4>—— 点击申请我们会在24小时之内联系您，同时点击加入我们，可与我们一起并肩作战 ——</h4>
            <div class="shanChu" @click="guanBi">
                <img src="@/assets/zuJian/gb.png" alt="">
            </div>
            <div class="anNiu">
                <input @click="tiYan" style="margin-top: 30px;" type="button" value="申请体验">
                <input @click="jiaRu" type="button" value="加入我们">
            </div>
        </div>
    </div>
</template>


<script>
export default {
    data() {
        return {
            
        };
    },
    methods:{
        guanBi(){
            const kk = document.getElementById("kuangJia");
            kk.style.display = "none";
        },
        tiYan(){
            window.location.href = "/yuYueYanShi";
        },
        jiaRu(){
            window.location.href = "/lianxi";
        }
    }
}
</script>

<style scoped>
.kuangJia{
    width: 60%;
    min-width: 1000px;
    height: 150px;
    margin: 0 auto;
    margin-top: 50px;
    border-radius: 10px;
    background-image: url(@/assets/zuJian/bj.png);
    display: block ;
    box-shadow: 1px 2px 7px 0px rgba(150, 149, 149, 0.5);
    margin-bottom: 1px;
}
.kuangJia h1{
    color: white;
    float: left;
    margin-top: 20px;
    margin-left: 50px;   
}
.kuangJia h4{
    color: white;
    float: left;
    margin-top: 95px;
    margin-left: -680px;
    font-size: 17px;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.shanChu{
    position: relative;
    float: right;
    right: 15px;
    top: 15px;
    width: 20px;
    height: 20px;
}
.shanChu:hover{
    animation: rotate 2s infinite linear;
}
.shanChu img{
    width: 100%;
    height: 100%;
}
.anNiu{
    width: 200px;
    height: 100%;
    margin-left: 780px;
}
input{
    width: 70%;
    height: 35px;
    margin-top: 25px;
    font-size: 17px;
    border-radius: 25px;
    background-color: rgb(255, 255, 255);
    border: 0;
    color: #2b6dfa;
    padding: 5px 0;
    font-weight: 550;
    letter-spacing: 2px;
}
input:hover{
    box-shadow: 0px 0px 3px 1px rgba(255, 255, 255, 0.5);
    color: #2b96fa;
}
</style>