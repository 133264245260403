<template>
  <div style="padding: 0 100px;">
    <div class="header">
      <h2 style="padding-left: 30px;">最新消息</h2>
      <a href="/huodong" class="head">
        更多新闻
      <i>></i>
      </a>
    </div>
    <br>
    <hr>

    <div style="width: 85%; padding: 50px 0; margin: 0 auto;">
      <el-carousel :interval="4000" type="card" height="360px"  indicator-position="none" effect="flatcard">
        <el-carousel-item>
          <div class="body2">
            <div class="border2">
              <img src="@/assets/home/217时间.png" class="icon">
              <p>2023-06-26 19:14</p>
            </div>
            <h3><router-link to="/huodong4">万维腾景自研国企数字化资产管理平台正式上线</router-link></h3>
            <div class="border3">
              <a href="/huodong4">了解详情<i>></i></a>
            </div>
            <div class="position">
              <img src="@/views/component/image/zcgl.png" alt="" />
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="body2">
            <div class="border2">
              <img src="@/assets/home/217时间.png" class="icon">
              <p>2023-02-02 21:40</p>
            </div>
            <h3><router-link to="/huodong2">万维腾景与青岛博士后产业投资发展集团签署战略合作</router-link></h3>
            <div class="border3">
              <a href="/huodong2">了解详情<i>></i></a>
            </div>
            <div class="position">
              <img src="@/views/component/image/img2.png" alt="" />
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="body2">
            <div class="border2">
              <img src="@/assets/home/217时间.png" class="icon">
              <p>2023-02-02 21:40</p>
            </div>
            <h3><router-link to="/huodong3">万维腾景与华通图新达成战略合作，打造城市级车城一体化产业平台</router-link></h3>
            <div class="border3">
              <a href="/huodong3">了解详情<i>></i></a>
            </div>
            <div class="position">
              <img src="@/views/component/image/qianyue.png" alt="" />
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="body2">
            <div class="border2">
              <img src="@/assets/home/217时间.png" class="icon">
              <p>2024-05-07 19:03</p>
            </div>
            <h3><router-link to="/huodong7">万维腾景自研国资监管平台功能升级...</router-link></h3>
            <div class="border3">
              <a href="/huodong7">了解详情<i>></i></a>
            </div>
            <div class="position">
              <img src="@/views/component/image/huoDong7/img.png" alt="" />
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="body2">
            <div class="border2">
              <img src="@/assets/home/217时间.png" class="icon">
              <p>2023-11-08 14:58</p>
            </div>
            <h3><router-link to="/huodong5">创业大厦自助洗车试营业——新人享优惠低至0.1元</router-link></h3>
            <div class="border3">
              <a href="/huodong5">了解详情<i>></i></a>
            </div>
            <div class="position">
              <img src="@/views/component/image/xiche.jpg" alt="" />
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="body2">
            <div class="border2">
              <img src="@/assets/home/217时间.png" class="icon">
              <p>2024-02-04 14:54</p>
            </div>
            <h3><router-link to="/huodong6">迎新春 展新颜|我县投放1000辆新款公共自行车</router-link></h3>
            <div class="border3">
              <a href="/huodong6">了解详情<i>></i></a>
            </div>
            <div class="position">
              <img src="@/views/component/image/huoDong6/2.jpg" alt="" />
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>

  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.el-carousel{
  height: 430px;
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.el-carousel__item{
  width: 45%;
  height: 430px;
  box-shadow: 1px 2px 5px 2px rgba(119, 119, 119, 0.5);
  background-color: #fff;
  border-radius: 10px;
  background-color: #f0f7ff;
}
::v-deep .el-carousel__arrow{
  right: 5%;
  top: 60%;
}
.body2{
  width: 100%;
  height: 100%;
}
.body2 h3{
  text-align: left;
  padding: 10px 0;
  padding-left: 50px;
  padding-right: 30px;
  white-space: nowrap;      /*超出的空白区域不换行*/
  overflow: hidden;         /*超出隐藏*/
  text-overflow: ellipsis;  /*文本超出显示省略号*/
}
.border2{
  margin-top: 30px;
  padding-left: 50px;
  display: flex;
  color: rgb(22, 34, 117);
}
.border2 p {
  margin-left: 20px;
  font-size: 16px;
}
.border3{
  text-align: left;
  padding-left: 50px;
  font-size: 18px;
}
.position{
  border-radius: 10px;
  margin: 0 auto;
  margin-top: 10px;
  width: 63%;
  box-shadow: 1px 2px 5px 2px rgba(119, 119, 119, 0.5);
  height: 60%;
  transition: all .3s;
}
.position img{
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
.position:hover{
  transform:scale(1.01);
  transition: all ease-out 0.5s;
  -webkit-transition: .3s;
}
.header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
.header i{
  margin-left: 10px;
}
.head{
  width: 100px;
  height: 35px;
  line-height: 35px;
  padding-left: 10px;
  background-color: #fff;
  border-radius: 5px;
  display: inline-block;
  position: relative;
  top: -25px;
  right: 30px;
  text-align: left;
  transform: translate3d(0, 60%, 0);
  border: 1px solid #333;
  transition: all .3s;
}
a{
  text-decoration: none;
}
i{
  margin-left: 10px;
  transition: all .3s;
}
a:hover i {
  margin-left: 20px;
  transition: all .3s;
  color: #0099ff;
}
a:hover{
  color: #0099ff;
}
.icon{
  width: 18px;
}
</style>